<script setup lang="ts">
import { type ILeaseVariation, useBacklink } from '@register'
import { useAmendmentFieldSubmit } from '@register/components/Amendment/Edit/composables/useAmendmentFieldSubmit'
import { leaseStatusObject } from '~/constants/LeaseStatus'
import type { LeaseDocumentValue } from '~/layers/ui/formkit/inputs/leaseDocumentInput/types'

// PROPS
const props = withDefaults(
  defineProps<{
    variation: ILeaseVariation
    disabled?: boolean
  }>(),
  {
    disabled: false,
  },
)

const defaultValue: LeaseDocumentValue = {
  value: props.variation.value,
  bounds: props.variation.bounds
    ? {
        bounds: props.variation.bounds ?? [],
        documentId: props.variation.documentId,
      }
    : null,
  isApproved: props.variation.isApproved ?? false,
}

// FIELD SUBMIT
const { onSubmit, fieldValue } = useAmendmentFieldSubmit(
  toRef(props, 'variation'),
)

const input = computed(() => {
  return leaseStatusObject[
    props.variation.value as keyof typeof leaseStatusObject
  ]
})
</script>

<template>
  <div class="flex h-full flex-col items-start">
    <FormKit
      :id="variation.id"
      v-model="fieldValue"
      type="hidden"
      :name="variation.id"
      :value="defaultValue"
      @input="onSubmit"
    />

    <Tooltip
      placement="top"
      class="bg-gray-950 text-xs"
      content="Status is automatically changed based on the expiry date"
    >
      <span
        class="formkit-label mb-2 block text-sm font-medium text-gray-900 dark:text-white"
      >
        {{
          variation.isApproved ? 'Status changed to:' : 'Status will change to:'
        }}
      </span>

      <span
        class="flex cursor-not-allowed rounded-full px-4 py-0.5"
        :class="input.color"
      >
        {{ input.label }}
      </span>
    </Tooltip>
  </div>
</template>
